const storeName = 'line-login-store'

function open () {
  if (!window.indexedDB) {
    // console.error("Your browser doesn't support a stable version of IndexedDB.")
    return Promise.resolve(null)
  }

  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = window.indexedDB.open('MyTestDatabase', 3)
    request.onerror = (e) => {
      // console.log('db error.', e)
      reject(e)
    }
    request.onsuccess = (event) => {
      return resolve(request.result as IDBDatabase)
    }

    request.onupgradeneeded = (e) => {
      request.result.createObjectStore(storeName, { keyPath: 'token' })
    }

    request.addEventListener('close', () => {
      // console.log('db close.... OK')
    })
  })
}

async function getItem (key: string) {
  const db = await open()
  if (!db) {
    return null
  }

  return new Promise<LineIndexTable>((resolve, reject) => {
    const req = db.transaction(storeName, 'readonly').objectStore(storeName).get(key)
    if (!req) {
      return
    }

    req.onsuccess = (e) => {
      db.close()
      return resolve(req.result)
    }
  })
}

async function setItem (key: string, value?: string | object) {
  const db = await open()
  if (!db) {
    return null
  }

  return new Promise<number>((resolve, reject) => {
    const trans = db.transaction(storeName, 'readwrite')
    const req = trans.objectStore(storeName).put(value)

    req.onsuccess = (e) => {
      return resolve(e.timeStamp)
    }

    trans.oncomplete = (e) => {
      db.close()
    }
  })
}

async function deleteItem (key: string) {
  const db = await open()
  if (!db) {
    return null
  }

  return new Promise<number>((resolve, reject) => {
    const trans = db.transaction(storeName, 'readwrite')
    const req = trans.objectStore(storeName).delete(key)

    req.onsuccess = (e) => {
      return resolve(e.timeStamp)
    }

    trans.oncomplete = (e) => {
      db.close()
    }
  })
}

export default function () {
  return {
    getItem: async (key:string) => await getItem(key),
    setItem: async (key:string, value:string | object) => await setItem(key, value),
    deleteItem: async (key: string) => await deleteItem(key)
  }
}
